<template>
    <div>
        <div class="container">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>
                    <i class="el-icon-pie-chart"></i> 客流量分析
                </el-breadcrumb-item>
            </el-breadcrumb>

            
        </div>
    </div>
</template>

<script>
import {getLatestMonthDateArrayByDate,getMonthDateArrayByPrePost} from "@/utils/time";
import {getToken} from "@/utils/auth";
import { mapGetters } from "vuex";


import Schart from 'vue-schart';
export default {
    name: 'basecharts',
    components: {
      
    },
    data() {
        return {

        };
    },

    computed: {
        ...mapGetters(["Analysis_ByMonth","Analysis_Area","Analysis_Product","Analysis_Category"])
    },

    async mounted() {

    },

    methods:{
   
        
    }

};

</script>

<style scoped>
.schart-box {
    display: inline-block;
    margin: 20px;
    width: 20%;
    margin-left:150px;
}

.schart {
    width: 98%;
    height: 320px;
}
.content-title {
    clear: both;
    font-weight: 400;
    line-height: 50px;
    margin: 10px 0;
    font-size: 22px;
    color: #1f2f3d;
}
</style>